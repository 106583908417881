import React from 'react';
import { ThemeProvider, CSSReset, Flex } from '@chakra-ui/core';
import theme from './theme';
import Body from './Body';

function App() {
  return (
    <ThemeProvider theme={theme}>
    <CSSReset />
    <Flex direction="column" pt={[12, 16, 16, 32]} pb={[12, 16, 16, 32]} pl={[8, 16, 16, 24]} pr={[8, 16, 16, 24]}>
      <Body />
    </Flex>
  </ThemeProvider>
  );
}

export default App;
