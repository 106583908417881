import React from 'react';
import {
    Button as CButton,
    Link as CLink,
} from '@chakra-ui/core';

export const Link = (props) => {
    return (
        <CLink
            color="black"
            fontWeight="medium"
            _hover={{ color: "pink.500", textDecoration: "none"}}
            _focus={{ border:"0" }}
            {...props}
        />
    );
};

export const LinkButton = (props) => {
    return (
        <CButton 
            variant="outline" 
            fontWeight="semiBold" 
            color="black" 
            fontSize="md" 
            marginRight="3"
            height="32px" 
            rounded="16px"
            border="1px"
            borderColor="gray.300"
            paddingX="20px"
            _hover={{ bg:"black", color:"white", borderColor:"black"}}  
            _active={{ border:"1"}}
            _focus={{ border:"1" }}
            {...props}
        />
    );
};