import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/core';
import { Link, LinkButton } from './Core';

function Body() {

    const showTwitter = () => {
        window.open("https://twitter.com/jans", "_self")
    }

    const showInsta = () => {
        window.open("https://instagram.com/jansenderek", "_self")
    }

    return (
        <Flex maxW="480px" align="left" direction="column">
            <Box fontSize={["2xl", "3xl", "3xl", "4xl"]}>
                <Text fontWeight="bold">Jan Senderek</Text>
            </Box>
            <Box>
                <Text fontWeight="light" lineHeight="taller" marginTop="8">Hi there. I’m Jan, and I build software products. My previous company <Link href="https://bit.ly/32k7W6W">Loom</Link> was acquired by Dropbox. Recently, I've started a new company, <Link href="http://bit.ly/3YEXuBV">Frond</Link>. Occasionally, I help other founders as an advisor or <Link href="https://bit.ly/2Wpaayd">investor</Link>.</Text>
            </Box>
            <Box mt="16">
                <Flex>
                    {/* <LinkButton onClick={showTwitter}>Twitter</LinkButton> */}
                    {/* <LinkButton onClick={showInsta}>Instagram</LinkButton> */}
                    <LinkButton as="a" href="mailto:hello@jansen.co">Get in touch</LinkButton>
                </Flex>
            </Box>
        </Flex>
    );
};

export default Body;